import {shallowEqual, useSelector} from "react-redux";
import {StoreState} from "src/reducers";
import {useParams} from "react-router-dom";
import { MIXPANEL_ACTION_SUB_TYPES, MIXPANEL_ACTION_TYPES, MIXPANEL_ACTIONS } from "src/constans/mixpanelEnum";
import MixpanelService from "src/services/mixpanelService";

interface UseMixpanelSelectorResult {
    icuName: string;
    userName: string;
    tenantName: string;
}

const useMixpanelSelector = ({icus, tenant, authInfo}: StoreState): UseMixpanelSelectorResult => ({
    icuName: icus[0]?.name || '',
    userName: authInfo?.currentUser?.email || '',
    tenantName: tenant?.name || '',
})

export const useMixpanel = () => {
    const {icuName, userName, tenantName} = useSelector(useMixpanelSelector, shallowEqual)
    const params = useParams();
    const deviceId = params.deviceId ? params.deviceId : "";
    const MixpanelInstance = MixpanelService.getInstance()

    const commonProperties = {icuName, userName, tenantName, deviceId};

    function trackOpenDailySchedule() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.OPEN_MODAL, {type: MIXPANEL_ACTION_TYPES.DAILY_SCHEDULE, ...commonProperties});
    }

    function trackCloseDailySchedule() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.CLOSE_MODAL, {type: MIXPANEL_ACTION_TYPES.DAILY_SCHEDULE, ...commonProperties});
    }

    function trackSendingMusic() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.COMMUNICATION_SENT, {type: MIXPANEL_ACTION_TYPES.MUSIC, ...commonProperties});
    }

    function trackSendingFamilyMessage() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.COMMUNICATION_SENT, {type: MIXPANEL_ACTION_TYPES.FAMILY_MESSAGES, ...commonProperties});
    }

    function trackOpenStopCommunicationModal() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.OPEN_MODAL, {type: MIXPANEL_ACTION_TYPES.STOP_COMMUNICATION, ...commonProperties});
    }

    function trackStopCommunicationModalClickStop() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.STOP_COMMUNICATION, commonProperties);
    }

    function trackCloseStopModalUsingXButton() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.CLOSE_MODAL, {
            type: MIXPANEL_ACTION_TYPES.STOP_COMMUNICATION,
            subType: MIXPANEL_ACTION_SUB_TYPES.X_BUTTON,
            ...commonProperties
        });
    }

    function trackCloseStopModalUsingCloseButton() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.CLOSE_MODAL, {
            type: MIXPANEL_ACTION_TYPES.STOP_COMMUNICATION,
            // subType: ACTION_SUB_TYPES.CLOSE_BUTTON,
            ...commonProperties
        });
    }

    function trackOpenMoreActionMenu() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.MORE_ACTION_OPEN, commonProperties);
    }

    function trackCloseMoreActionMenu() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.MORE_ACTION_CLOSE, commonProperties);
    }

    function trackOpenVideoStream() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.VIDEO_STREAM_OPEN, commonProperties);
    }

    function trackCloseVideoStream() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.VIDEO_STREAM_CLOSE, commonProperties);
    }

    function trackOpenEditPatientDetailsModal() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.EDIT_PATIENT_OPEN, commonProperties);
    }

    function trackCloseEditPatientDetailsModalCancelButton() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.EDIT_PATIENT_CANCEL, {
            type: MIXPANEL_ACTION_TYPES.EDIT_PATIENT,
            subType: MIXPANEL_ACTION_SUB_TYPES.CANCEL_BUTTON,
            ...commonProperties
        });
    }

    function trackCloseEditPatientDetailsModalXButton() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.EDIT_PATIENT_CANCEL, {
            type: MIXPANEL_ACTION_TYPES.EDIT_PATIENT,
            subType: MIXPANEL_ACTION_SUB_TYPES.X_BUTTON,
            ...commonProperties
        });
    }

    function trackSaveEditPatientDetailsModal() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.EDIT_PATIENT_SAVE, commonProperties);
    }

    function trackCloseDeviceOffAlert() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.ALERT_CLOSE, {type: MIXPANEL_ACTION_TYPES.DEVICE_OFF, ...commonProperties});
    }

    function trackCloseWifiDisconnectedAlert() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.ALERT_CLOSE, {type: MIXPANEL_ACTION_TYPES.WIFI_DISCONNECTED, ...commonProperties});
    }

    function trackClosePowerDisconnectedAlert() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.ALERT_CLOSE, {type: MIXPANEL_ACTION_TYPES.POWER_DISCONNECTED, ...commonProperties});
    }

    function trackCloseTechnicalIssueAlert() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.ALERT_CLOSE, {type: MIXPANEL_ACTION_TYPES.TECHNICAL_ISSUE, ...commonProperties});
    }

    function trackOpenResponseModal() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.OPEN_MODAL, {type: MIXPANEL_ACTION_TYPES.RESPONSES, ...commonProperties});
    }

    function trackCloseResponseModal() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.CLOSE_MODAL, {type: MIXPANEL_ACTION_TYPES.RESPONSES, ...commonProperties});
    }

    function trackCloseNotifyModalCancelButton() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.CLOSE_MODAL, {
            type: MIXPANEL_ACTION_TYPES.NOTIFY,
            subType: MIXPANEL_ACTION_SUB_TYPES.CANCEL_BUTTON,
            ...commonProperties
        });
    }

    function trackCloseNotifyModalXButton() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.CLOSE_MODAL, {
            type: MIXPANEL_ACTION_TYPES.NOTIFY,
            subType: MIXPANEL_ACTION_SUB_TYPES.X_BUTTON,
            ...commonProperties
        });
    }

    function trackOpenAddPatientModal() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.ADD_PATIENT_OPEN, commonProperties);
    }

    function trackCloseAddPatientModalCancelButton() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.ADD_PATIENT_CANCEL, {
            subType: MIXPANEL_ACTION_SUB_TYPES.CANCEL_BUTTON,
            ...commonProperties
        });
    }

    function trackCloseAddPatientModalXButton() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.ADD_PATIENT_CANCEL, {
            subType: MIXPANEL_ACTION_SUB_TYPES.X_BUTTON,
            ...commonProperties
        });
    }

    function trackSaveAddPatientModal() {
        MixpanelInstance.trackEvent(MIXPANEL_ACTIONS.ADD_PATIENT_SAVE, commonProperties);
    }

    return {
        trackOpenDailySchedule,
        trackCloseDailySchedule,
        trackSendingMusic,
        trackSendingFamilyMessage,
        trackOpenStopCommunicationModal,
        trackStopCommunicationModalClickStop,
        trackCloseStopModalUsingXButton, // TODO: needs refactoring of Stop communication modal to implement this event
        trackCloseStopModalUsingCloseButton,
        trackOpenMoreActionMenu,
        trackCloseMoreActionMenu,
        trackOpenVideoStream,
        trackCloseVideoStream,
        trackOpenEditPatientDetailsModal,
        trackCloseEditPatientDetailsModalCancelButton,
        trackCloseEditPatientDetailsModalXButton,
        trackSaveEditPatientDetailsModal,
        trackCloseDeviceOffAlert,
        trackCloseWifiDisconnectedAlert,
        trackClosePowerDisconnectedAlert,
        trackCloseTechnicalIssueAlert,
        trackOpenResponseModal,
        trackCloseResponseModal,
        trackCloseNotifyModalCancelButton, // TODO: implement after verification
        trackCloseNotifyModalXButton, // TODO: implement after verification
        trackOpenAddPatientModal,
        trackCloseAddPatientModalCancelButton,
        trackCloseAddPatientModalXButton,
        trackSaveAddPatientModal
    }
}